import React from 'react'
import { ErrorMessage } from 'formik';
import AsyncSelect from "react-select/async";
import { Notif } from '../_components';
import axios from 'axios';
import { axiosHeader } from '../_helpers';

const { REACT_APP_API_URL :  API_URL} =process.env

class SelectAPI extends React.Component{
	constructor(props){
    super(props)
    this.state={
    		axiosHeader : axiosHeader(),
    	}
	}



    loadOptions = (inputValue)=> {
	    return new Promise(resolve => {

	    	if(inputValue.length > 2){
	    	   axios.all([
			     axios.get(`${API_URL}${this.props.url}?name=${inputValue}&code=${inputValue}${this.props.add_url ? this.props.add_url : ''}`, this.state.axiosHeader)
			   ])
			   .then(axios.spread((params) => {
			     	resolve(params.data.slice(0, this.props.view))
			   }))
			   .catch(({response : { request : { response : error }} }) => {
		            Notif('Opssss', error,'toast-bottom-right','4')
		            resolve(null)
		       });

            }else{
            	resolve(this.props.options)
            }

	    })
	  };




render() {
  const {errors, touched, name, select_ref, handleSelect, view, options, defaultValue, disabled, isMulti, onMenuOpen, placeholder=''}=this.props


 return(
        <React.Fragment>
              
		         <AsyncSelect
		       	 {...{
		       	 	...(select_ref && { ref : select_ref}),
		       	 	...(isMulti && {isMulti}),
		       	 	name,
					...(placeholder && {placeholder}),
		       	 	isDisabled : disabled ? true : false,
		       	 	defaultOptions : options.slice(0, view),
		       	 	className : `basic-multi-select ${errors && touched ? ' is-invalid' : '' }`,
		       	 	classNamePrefix : 'select',
		       	 	loadOptions : (inputValue) => this.loadOptions(inputValue),
					...(onMenuOpen && { onMenuOpen }),
		       	 	defaultValue,
		       	 	onChange: (selectChoice, {name}) =>{handleSelect(selectChoice, {name})}
		       	 }}
		        />

              <ErrorMessage name={name} component="div" style={{color:'red'}} className="invalid-feedback" />
        </React.Fragment>
    )
  }
}

export { SelectAPI }