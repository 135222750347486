
export {
    Modal,
    Direct
}


function Modal(params) {
    return dispatch => {
        dispatch({type: 'MODAL_COMPONENTS', params })
       
    };
}

function Direct(params) {
    return dispatch => {
        dispatch({type: 'DIRECT', params })
       
    };
}



