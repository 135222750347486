import React from 'react'
import moment from 'moment'

 class Footer extends React.Component{


// componentDidMount =  async ()=>{
// // if ('geolocation' in navigator) {
//     navigator.geolocation.getCurrentPosition(function (location) {
//       console.log('location', location)
//     });

//     let permissionName='geolocation',descriptor 
//     navigator.permissions.query(Object.assign({name: permissionName}, descriptor))
//       .then(function (permission) {
//         // document.getElementById(permissionName + '-status').innerHTML = permission.state;
//         // permission.addEventListener('change', function (e) {
//         //   document.getElementById(permissionName + '-status').innerHTML = permission.state;
//         //   handleChange(permissionName, permission.state);
//         // });
//         permission.state='granted'
//         console.log('___________',permission.state)
//       });
      



// }


render() {
	return(
			<div className="footer">
                <div className="pull-right">
                     <span style={{fontSize: 12}}>by</span> <strong>Dev : <u>V. 1.2.1</u></strong>
                </div>
                <div>
                    <strong>Copyright</strong>  <span style={{fontSize: 12}} >sistem informasi akuntansi © {moment().format('yyyy')} </span>
                </div>
            </div>
		);
	}
}


export {Footer}