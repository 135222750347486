import React from 'react'
import DatePicker from 'react-datepicker';
import { Field, ErrorMessage } from 'formik';

export class Tanggal extends React.Component{

render() {
const { name, onChange, showTime, value, maxDate, minDate, errors, touched, disabled, placeholderText} = this.props
 return(
          <div>
            <Field name={name}
                   render={({ field }) => (
             	 <DatePicker
                    {...field}
                    className={`form-control ${(errors && touched ? ' is-invalid' : '')}`}
                    selected={value}
                    onChange={onChange}
                    dateFormat="dd/MM/yyyy"
                    disabled={disabled ? true : false }
                    showTimeInput={showTime}
                    minDate={minDate ? minDate : null}
                    maxDate={maxDate ? maxDate : null}
                    placeholderText={placeholderText ? placeholderText : ''}
                    // isClearable
                  />
              )} 
            />
             	<ErrorMessage 
                  name={name} 
                  component="div" style={{color:'red'}} 
                  className="invalid-feedback" />
          </div>
    )
  }

}

