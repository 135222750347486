import React from 'react'
import {PrivateRoute} from '../_components';
import { Link, Switch} from 'react-router-dom';
import $ from 'jquery';

export class TopTab extends React.Component{


render() {
const {component, location: { pathname }, parentCallback } = this.props
if(component){
let parent=''
 return(
     <div className="row">
      <div className="col-lg-12">
        <div className="tabs-container">
           <ul className="nav nav-tabs">
            {
               
                component && component.map((k, key)=>{
                    if(k.name){
                        return(
                        <li className={''+ (pathname===k.link ? 'active': '')}>
                        <Link key={key} 
                              to={`${k.link}`}>{k.name} &nbsp;&nbsp;
                              <span className={k.icon}></span>
                        </Link>
                        </li>
                        )
                     
                    }else{
                        return null
                    }

                })

            }
          </ul>
        </div>

        <div className="tab-content active" >
                 <div className="">
                  {
                    component && component.map((k, key)=>{
                        return(
                            <PrivateRoute exact={k.name ? true : false } path={`${k.path}`} parentCallback={parentCallback} component={k.component} />
                        )
                    })

                }
              </div>
        </div>
      </div>
    </div>

    )}else{
        return null
    }
  }

}

