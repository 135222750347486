import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, Location } from 'react-router-dom';
import $ from 'jquery';
import { smoothlyMenu, history } from '../_helpers';
import { authenticationService } from '../_services';
import { Notif, HakAkses } from '../_components';

class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user : authenticationService.currentUserValue
        }
    }


    componentDidMount() {
     // if(authenticationService.currentUser){
     //    authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
     // } 
     const { menu } = this.refs;
       $(function() {
          $(menu).metisMenu({
            toggle: true
          });
        });
    }

     componentWillUpdate() {
        // $('body').toggleClass('mini-navbar');
        smoothlyMenu();
    }

    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    }

    secondLevelActive(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
    }

    thirdLevelActive(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "nav nav-third-level collapse in" : "nav nav-third-level collapse";
    }

    logout() {
        authenticationService.logout();
        this.props.history.push('/login');
        Notif(`Let's start again !`, 'You not login now','toast-top-right','4')
    }

    render() {
        const {name, email}=this.state.user
        return (
            <nav className="navbar-default navbar-static-side" role="navigation">
                    <ul className="nav metismenu" id="side-menu" ref="menu">
                        <li className="nav-header">
                            <div className="dropdown profile-element"> <span>
                             </span>
                                <a data-toggle="dropdown" className="dropdown-toggle" href="#">
                            <span className="clear"> <span className="block m-t-xs"> <strong className="font-bold">{name}</strong>
                             </span> <span className="text-muted text-xs block">{email}<b className="caret"></b></span> </span> </a>
                                <ul className="dropdown-menu animated fadeInRight m-t-xs">
                                    <li>
                                        <a onClick={()=>this.logout()}> Logout</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="logo-element">
                                SIA
                            </div>
                        </li>
                        
                        
                        <HakAkses id={1}>
                            <li>
                            <Link to="/">
                                <i className="fa fa-dashboard"></i> 
                                    <span className="nav-label">Dashboard</span>
                            </Link>
                            </li>
                        </HakAkses>
                        
                        <HakAkses id={2}>
                            <li>
                                <a href="#">
                                    <i className="fa fa-cogs"></i> 
                                    <span className="nav-label">Setting</span> 
                                    <span className="fa arrow"></span>
                                  </a>
                                 <ul className="nav nav-second-level collapse">
                                    <HakAkses id={188}>
                                        <li><Link to="/regional">Regional</Link></li>
                                    </HakAkses> 
                                    <HakAkses id={3}>
                                        <li><Link to="/cabang">Cabang</Link></li>
                                    </HakAkses>
                                    <HakAkses id={9}>
                                        <li><Link to="/users">Users</Link></li>
                                    </HakAkses>
                                    <HakAkses id={15}>
                                        <li><Link to="/accounts">Daftar Akun</Link></li>
                                    </HakAkses>
                                    <HakAkses id={21}>
                                        <li><Link to="/def_accounts">Setting Def Akun</Link></li>
                                    </HakAkses> 
                                    <HakAkses id={23}>
                                        <li><Link to="/pajak">Master Pajak</Link></li>
                                    </HakAkses>
                                   
                                    <HakAkses id={175}>
                                    <li>
                                        <a href="#">
                                             Master Items
                                            <span className="fa arrow"></span>
                                          </a>
                                        <ul className="nav nav-third-level collapse">
                                             <HakAkses id={176}>
                                                <li><Link to="/kat_items">Kategori Items</Link></li>
                                             </HakAkses> 
                                             <HakAkses id={29}>
                                                <li><Link to="/items">Items</Link></li>
                                             </HakAkses> 
                                             <HakAkses id={182}>
                                                <li><Link to="/paket_items">Paket Items</Link></li>
                                             </HakAkses>
                                             
                                        </ul>
                                    </li>
                                    </HakAkses> 

                                    <HakAkses id={35}>
                                    <li>
                                        <a href="#">
                                             Setting Saldo Awal
                                            <span className="fa arrow"></span>
                                          </a>
                                        <ul className="nav nav-third-level collapse">
                                             <HakAkses id={36}>
                                                <li><Link to="/saldo_akun">Akun</Link></li>
                                             </HakAkses> 
                                             <HakAkses id={42}>
                                                <li><Link to="/saldo_piutang">Piutang</Link></li>
                                             </HakAkses> 
                                             <HakAkses id={48}>
                                                <li><Link to="/saldo_hutang">Hutang</Link></li>
                                             </HakAkses>
                                             <HakAkses id={54}>
                                                <li><Link to="/saldo_umcustomers">UM Customers</Link></li>
                                             </HakAkses> 
                                             <HakAkses id={60}>
                                                <li><Link to="/saldo_umsuppliers">UM Suppliers</Link></li>
                                             </HakAkses> 
                                             <HakAkses id={66}>
                                                <li><Link to="/saldo_inventory">Inventory</Link></li>
                                             </HakAkses>
                                        </ul>
                                    </li>
                                    </HakAkses>

                                    <HakAkses id={214}>
                                    <li>
                                        <a href="#">
                                             Poin of Sales
                                            <span className="fa arrow"></span>
                                          </a>
                                        <ul className="nav nav-third-level collapse">
                                             <HakAkses id={215}>
                                                <li><Link to="/cara_pembayaran">Cara Pembayaran</Link></li>
                                             </HakAkses> 
                                             <HakAkses id={220}>
                                                <li><Link to="/discount">Ketentuan Diskon</Link></li>
                                             </HakAkses> 
                                        </ul>
                                    </li>
                                    </HakAkses>

                                    <HakAkses id={72}>
                                        <li><Link to="/cash_kategori">Kategori Kas</Link></li>
                                    </HakAkses>

                                 </ul>
                            </li>
                          
                        </HakAkses>
                         
                         <HakAkses id={78}>
                            <li>
                            <Link to="/kontak">
                                <i className="fa fa-address-book"></i> 
                                    <span className="nav-label">Kontak</span>
                            </Link>
                            </li>
                        </HakAkses>

                        <HakAkses id={85}>
                            <li>
                            <a href="#">
                                <i className="fa fa-cart-plus"></i> 
                                <span className="nav-label">Pembelian</span> 
                                <span className="fa arrow"></span>
                              </a>
                             <ul className="nav nav-second-level collapse">
                                <HakAkses id={86}>
                                    <li><Link to="/pembelian">Pembelian</Link></li>
                                </HakAkses>
                                <HakAkses id={98}>
                                    <li><Link to="/retur_pembelian">Retur Pembelian</Link></li>
                                </HakAkses>
                                
                             </ul>
                            </li>
                        </HakAkses> 

                        <HakAkses id={104}>
                            <li>
                            <a href="#">
                                <i className="fa fa-cart-arrow-down"></i> 
                                <span className="nav-label">Penjualan</span> 
                                <span className="fa arrow"></span>
                              </a>
                             <ul className="nav nav-second-level collapse">
                                <HakAkses id={105}>
                                    <li><Link to="/penjualan">Penjualan</Link></li>
                                </HakAkses> 
                                <HakAkses id={117}>
                                    <li><Link to="/retur_penjualan">Retur Penjualan</Link></li>
                                </HakAkses>
                                
                             </ul>
                            </li>
                        </HakAkses>

                        <HakAkses id={123}>
                            <li>
                            <a href="#">
                                <i className="fa fa-cubes"></i> 
                                <span className="nav-label">Inventory</span> 
                                <span className="fa arrow"></span>
                              </a>
                             <ul className="nav nav-second-level collapse">
                                <HakAkses id={124}>
                                    <li><Link to="/stock_opname">Stock Opname</Link></li>
                                </HakAkses> 
                                <HakAkses id={194}>
                                    <li><Link to="/mutasi_stock">Transfer Stock</Link></li>
                                </HakAkses> 
                             </ul>
                            </li>
                        </HakAkses>

                        <HakAkses id={200}>
                            <li>
                            <a href="#">
                                <i className="fa fa-industry"></i> 
                                <span className="nav-label">Produksi</span> 
                                <span className="fa arrow"></span>
                              </a>
                             <ul className="nav nav-second-level collapse">
                                <HakAkses id={201}>
                                    <li><Link to="/bom">Bill of Material</Link></li>
                                </HakAkses> 
                                <HakAkses id={207}>
                                    <li><Link to="/produksi">Produksi</Link></li>
                                </HakAkses> 
                             </ul>
                            </li>
                        </HakAkses>

                        <HakAkses id={130}>
                            <li>
                            <a href="#">
                                <i className="fa fa-balance-scale"></i> 
                                <span className="nav-label">Akuntansi</span> 
                                <span className="fa arrow"></span>
                              </a>
                             <ul className="nav nav-second-level collapse">

                                 <HakAkses id={144}>
                                    <li>
                                        <a href="#">
                                             Kas/ Bank
                                            <span className="fa arrow"></span>
                                          </a>
                                        <ul className="nav nav-third-level collapse">
                                             <HakAkses id={92}>
                                                <li><Link to="/pembayaran">Pembayaran</Link></li>
                                             </HakAkses> 
                                             <HakAkses id={111}>
                                                <li><Link to="/penerimaan">Penerimaan Pembayaran</Link></li>
                                             </HakAkses> 
                                            
                                        </ul>
                                    </li>
                                    </HakAkses>

                                <HakAkses id={150}>
                                    <li><Link to="/giro">Cheque/ Giro</Link></li>
                                </HakAkses> 
                                <HakAkses id={163}>
                                    <li><Link to="/jurnal_umum">Jurnal Umum</Link></li>
                                </HakAkses>
                                <HakAkses id={162}>
                                    <li><Link to="/laporan_keuangan">Laporan Keuangan</Link></li>
                                </HakAkses>
                             </ul>
                            </li>
                        </HakAkses>
                    </ul>

            </nav>
        )
    }
}

export { Navigation };