import React from 'react'
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';

export class ButtonLoading extends React.Component{

render() {
  const {loading, disabled, children, color='mint', onClick={}}=this.props
 return(
         <LaddaButton
          style={{paddingTop : 0}}
          loading={loading}
          type="submit"
          disabled={disabled}
          data-color={color}
          data-size={XS}
          onClick={onClick}
          data-style={EXPAND_RIGHT}
          data-spinner-size={20}
          data-spinner-color="#fff"
          data-spinner-lines={12}
        > {children}
      </LaddaButton> 
    )
  }
}
