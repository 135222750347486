import { combineReducers } from 'redux';
import { global_components } from './global_components';
import { item_filter, 
		 purchase_filter, 
		 sale_filter,
		 dashboard_filter,
		 opname_filter,
         mutation_filter,
         production_filter,
         jurnal_filter,
         paket_filter
		} from './filter';

export default combineReducers({
  global_components,
  dashboard_filter,
  item_filter,
  purchase_filter,
  sale_filter,
  opname_filter,
  mutation_filter,
  production_filter,
  jurnal_filter,
  paket_filter
});

