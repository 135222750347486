import React from 'react'
import { Link } from 'react-router-dom'

class Breadcrumb extends React.Component{

  constructor(props){
    super(props)
    this.state={
      br: [
           {
              id : 1,
              link : [
                    {path : '/',name : 'Dashboard', isactive: true}
                  ],
              Title : 'Dashboard'
          },{
              id : 2,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/cabang', name : 'Cabang', isactive: true}
                  ],
              Title : 'Cabang'
          },{
              id : 3,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/cabang', name : 'Cabang'},
                    {path : '/cabang/add', name : 'Add Cabang', isactive: true}
                  ],
              Title : 'Add Cabang'
          },{
              id : 4,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/cabang', name : 'Cabang'},
                    {path : `/cabang/edit/${this.props.pathId}`, name : 'Edit Cabang', isactive: true}
                  ],
              Title : 'Edit Cabang'
          },{
              id : 5,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/cabang', name : 'Cabang'},
                    {path : `/cabang/detail/${this.props.pathId}`, name : 'Detail Cabang', isactive: true}
                  ],
              Title : 'Detail Cabang'
          },{
              id : 6,
              link : [
                    {path : '/',name : 'Dashboard'},
                    {path : '/users',name : 'Users', isactive: true}
                  ],
              Title : 'Users'
          },{
              id : 7,
              link : [
                    {path : '/',name : 'Dashboard'},
                    {path : '/users',name : 'Users'},
                    {path : '/users/add',name : 'Add User', isactive: true}
                  ],
              Title : 'Add User'
          },{
              id : 8,
              link : [
                    {path : '/',name : 'Dashboard'},
                    {path : '/users',name : 'Users'},
                    {path : `/users/edit/${this.props.pathId}`,name : 'Edit User', isactive: true}
                  ],
              Title : 'Edit User'
          },{
              id : 9,
              link : [
                    {path : '/',name : 'Dashboard'},
                    {path : '/users',name : 'Users'},
                    {path : `/users/detail/${this.props.pathId}`,name : 'Detail User', isactive: true}
                  ],
              Title : 'Detail User'
          },{
              id : 10,
              link : [
                    {path : '/',name : 'Dashboard'},
                    {path : '/accounts',name : 'Akun', isactive: true},
                  ],
              Title : 'Daftar Akun'
          },{
              id : 11,
              link : [
                    {path : '/',name : 'Dashboard'},
                    {path : '/accounts',name : 'Akun' },
                    {path : '/accounts/add',name : 'Add Akun', isactive: true},
                  ],
              Title : 'Add Akun'
          },{
              id : 12,
              link : [
                    {path : '/',name : 'Dashboard'},
                    {path : '/accounts',name : 'Akun' },
                    {path : `/accounts/edit/${this.props.pathId}`,name : 'Edit Akun', isactive: true},
                  ],
              Title : 'Edit Akun'
          },{
              id : 13,
              link : [
                    {path : '/',name : 'Dashboard'},
                    {path : '/def_accounts',name : 'Def Akun' , isactive: true},
                  ],
              Title : 'Default Akun'
          },{
              id : 14,
              link : [
                    {path : '/',name : 'Dashboard'},
                    {path : '/users',name : 'Users'},
                    {path : `/users/detail/${this.props.pathId}`,name : 'Detail User'},
                    {path : `/users/detail/${this.props.pathId}/personal_info`,name : 'Personal Info', isactive: true},
                  ],
              Title : 'Personal Info'
          },{
              id : 15,
              link : [
                    {path : '/',name : 'Dashboard'},
                    {path : '/users',name : 'Users'},
                    {path : `/users/detail/${this.props.pathId}`,name : 'Detail User'},
                    {path : `/users/detail/${this.props.pathId}/ganti_passsword`,name : 'Ganti Password', isactive: true},
                  ],
              Title : 'Ganti Password'
          },{
              id : 16,
              link : [
                    {path : '/',name : 'Dashboard'},
                    {path : '/users',name : 'Users'},
                    {path : `/users/detail/${this.props.pathId}`,name : 'Detail User'},
                    {path : `/users/detail/${this.props.pathId}/previllage`,name : 'Previllage', isactive: true},
                  ],
              Title : 'Previllage'
          },{
              id : 17,
              link : [
                    {path : '/',name : 'Dashboard'},
                    {path : '/users',name : 'Users'},
                    {path : `/users/detail/${this.props.pathId}`,name : 'Detail User'},
                    {path : `/users/detail/${this.props.pathId}/user_logs`,name : 'User Logs', isactive: true},
                  ],
              Title : 'User Logs'
          },{
              id : 18,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/cabang', name : 'Cabang'},
                    {path : `/cabang/detail/${this.props.pathId}`, name : 'Detail'},
                    {path : `/cabang/detail/${this.props.pathId}/detail_info`, name : 'Detail Info', isactive: true}
                  ],
              Title : 'Detail Info'
          },{
              id : 19,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/cabang', name : 'Cabang'},
                    {path : `/cabang/detail/${this.props.pathId}`, name : 'Detail'},
                    {path : `/cabang/detail/${this.props.pathId}/penomoran_transaksi`, name : 'Format Penomoran', isactive: true}
                  ],
              Title : 'Format Penomeran Transaksi'
          },{
              id : 20,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/cabang', name : 'Cabang'},
                    {path : `/cabang/detail/${this.props.pathId}`, name : 'Detail'},
                    {path : `/cabang/detail/${this.props.pathId}/penomoran_transaksi`, name : 'Format Penomoran'},
                    {path : `/cabang/detail/${this.props.pathId}/penomoran_transaksi/${this.props.pathIdId}`, name : 'Detail Penomeran', isactive: true}
                  ],
              Title : 'Detail Penomeran Transaksi'
          },{
              id : 21,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/pajak', name : 'Master Pajak', isactive: true}
                  ],
              Title : 'Master Pajak'
          },{
              id : 22,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/items', name : 'Master Items', isactive: true}
                  ],
              Title : 'Master Items'
          },{
              id : 23,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/items', name : 'Master Items'},
                    {path : '/items/add', name : 'Add Master Item', isactive: true}
                  ],
              Title : 'Add Master Item'
          },{
              id : 24,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/items', name : 'Master Items'},
                    {path : `/items/edit/${this.props.pathId}`, name : 'Edit Master Item', isactive: true}
                  ],
              Title : 'Edit Master Item'
          },{
              id : 25,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/kontak', name : 'Kontak', isactive: true},
                  ],
              Title : 'Daftar Kontak'
          },{
              id : 26,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/kontak', name : 'Kontak'},
                    {path : '/kontak/add', name : 'Add Kontak', isactive: true},
                  ],
              Title : 'Add Kontak'
          },{
              id : 27,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/kontak', name : 'Kontak'},
                    {path : `/kontak/edit/${this.props.pathId}`, name : 'Edit Kontak', isactive: true},
                  ],
              Title : 'Edit Kontak'
          },{
              id : 28,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/kontak', name : 'Kontak'},
                    {path : `/kontak/detail/${this.props.pathId}`, name : 'Detail Kontak', isactive: true},
                  ],
              Title : 'Detail Kontak'
          },{
              id : 29,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/kontak', name : 'Kontak'},
                    {path : `/kontak/detail/${this.props.pathId}`, name : 'Detail Kontak'},
                    {path : `/kontak/detail/${this.props.pathId}/kontak_info`, name : 'Kontak Info', isactive: true},
                  ],
              Title : 'Kontak Info'
          },{
              id : 30,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_akun', name : 'Saldo Awal Akun', isactive: true},
                  ],
              Title : 'Saldo Awal Akun'
          },{
              id : 31,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_akun', name : 'Saldo Awal Akun'},
                    {path : `/saldo_akun/add`, name : 'Add Saldo Awal Akun', isactive: true},
                  ],
              Title : 'Add Saldo Awal Akun'
          },{
              id : 32,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_akun', name : 'Saldo Awal Akun'},
                    {path : `/saldo_akun/edit/${this.props.pathId}`, name : 'Edit Saldo Awal Akun', isactive: true},
                  ],
              Title : 'Edit Saldo Awal Akun'
          },{
              id : 33,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_akun', name : 'Saldo Awal Akun'},
                    {path : `/saldo_akun/detail/${this.props.pathId}`, name : 'Detail Saldo Awal Akun', isactive: true},
                  ],
              Title : 'Detail Saldo Awal Akun'
          },{
              id : 34,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_hutang', name : 'Saldo Awal Hutang', isactive: true},
                  ],
              Title : 'Saldo Awal Hutang'
          },{
              id : 35,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_hutang', name : 'Saldo Awal Hutang'},
                    {path : `/saldo_hutang/add`, name : 'Add Saldo Awal Hutang', isactive: true},
                  ],
              Title : 'Add Saldo Awal Hutang'
          },{
              id : 36,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_hutang', name : 'Saldo Awal Hutang'},
                    {path : `/saldo_hutang/edit/${this.props.pathId}`, name : 'Edit Saldo Awal Hutang', isactive: true},
                  ],
              Title : 'Edit Saldo Awal Hutang'
          },{
              id : 37,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_hutang', name : 'Saldo Awal Hutang'},
                    {path : `/saldo_hutang/detail/${this.props.pathId}`, name : 'Detail Saldo Awal Hutang', isactive: true},
                  ],
              Title : 'Detail Saldo Awal Hutang'
          },{
              id : 38,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_piutang', name : 'Saldo Awal Piutang', isactive: true},
                  ],
              Title : 'Saldo Awal Piutang'
          },{
              id : 39,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_piutang', name : 'Saldo Awal Piutang'},
                    {path : `/saldo_piutang/add`, name : 'Add Saldo Awal Piutang', isactive: true},
                  ],
              Title : 'Add Saldo Awal Piutang'
          },{
              id : 40,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_piutang', name : 'Saldo Awal Piutang'},
                    {path : `/saldo_piutang/edit/${this.props.pathId}`, name : 'Edit Saldo Awal Piutang', isactive: true},
                  ],
              Title : 'Edit Saldo Awal Piutang'
          },{
              id : 41,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_piutang', name : 'Saldo Awal Piutang'},
                    {path : `/saldo_piutang/detail/${this.props.pathId}`, name : 'Detail Saldo Awal Piutang', isactive: true},
                  ],
              Title : 'Detail Saldo Awal Piutang'
          },{
              id : 42,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_umcustomers', name : 'Saldo Awal UM Customers', isactive: true},
                  ],
              Title : 'Saldo Awal UM Customers'
          },{
              id : 43,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_umcustomers', name : 'Saldo Awal UM Customers'},
                    {path : `/saldo_umcustomers/add`, name : 'Add Saldo Awal UM Customers', isactive: true},
                  ],
              Title : 'Add Saldo Awal UM Customers'
          },{
              id : 44,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_umcustomers', name : 'Saldo Awal UM Customers'},
                    {path : `/saldo_umcustomers/edit/${this.props.pathId}`, name : 'Edit Saldo Awal UM Customers', isactive: true},
                  ],
              Title : 'Edit Saldo Awal UM Customers'
          },{
              id : 45,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_umcustomers', name : 'Saldo Awal UM Customers'},
                    {path : `/saldo_umcustomers/detail/${this.props.pathId}`, name : 'Detail Saldo Awal UM Customers', isactive: true},
                  ],
              Title : 'Detail Saldo Awal UM Customers'
          },{
              id : 46,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_umsuppliers', name : 'Saldo Awal UM Suppliers', isactive: true},
                  ],
              Title : 'Saldo Awal UM Suppliers'
          },{
              id : 47,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_umsuppliers', name : 'Saldo Awal UM Suppliers'},
                    {path : `/saldo_umsuppliers/add`, name : 'Add Saldo Awal UM Suppliers', isactive: true},
                  ],
              Title : 'Add Saldo Awal UM Suppliers'
          },{
              id : 48,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_umsuppliers', name : 'Saldo Awal UM Suppliers'},
                    {path : `/saldo_umsuppliers/edit/${this.props.pathId}`, name : 'Edit Saldo Awal UM Suppliers', isactive: true},
                  ],
              Title : 'Edit Saldo Awal UM Suppliers'
          },{
              id : 49,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_umsuppliers', name : 'Saldo Awal UM Suppliers'},
                    {path : `/saldo_umsuppliers/detail/${this.props.pathId}`, name : 'Detail Saldo Awal UM Suppliers', isactive: true},
                  ],
              Title : 'Detail Saldo Awal UM Suppliers'
          },{
              id : 50,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_inventory', name : 'Saldo Awal Inventory', isactive: true},
                  ],
              Title : 'Saldo Awal Inventory'
          },{
              id : 51,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_inventory', name : 'Saldo Awal Inventory'},
                    {path : `/saldo_inventory/add`, name : 'Add Saldo Awal Inventory', isactive: true},
                  ],
              Title : 'Add Saldo Awal Inventory'
          },{
              id : 52,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_inventory', name : 'Saldo Awal Inventory'},
                    {path : `/saldo_inventory/edit/${this.props.pathId}`, name : 'Edit Saldo Awal Inventory', isactive: true},
                  ],
              Title : 'Edit Saldo Awal Inventory'
          },{
              id : 53,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/saldo_inventory', name : 'Saldo Awal Inventory'},
                    {path : `/saldo_inventory/detail/${this.props.pathId}`, name : 'Detail Saldo Awal Inventory', isactive: true},
                  ],
              Title : 'Detail Saldo Awal Inventory'
          },{
              id : 54,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/cash_kategori', name : 'Kategori Kas', isactive: true},
                  ],
              Title : 'Kategori Kas'
          },{
              id : 55,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/cash_kategori', name : 'Kategori Kas'},
                    {path : `/cash_kategori/detail/${this.props.pathId}`, name : 'Detail Kategori Kas', isactive: true},
                  ],
              Title : 'Detail Kategori Kas'
          },{
              id : 56,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/pembelian', name : 'Pembelian', isactive: true},
                  ],
              Title : 'Pembelian'
          },{
              id : 57,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/pembelian', name : 'Pembelian'},
                    {path : `/pembelian/add`, name : 'Add Pembelian', isactive: true},
                  ],
              Title : 'Add Pembelian'
          },{
              id : 58,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/pembelian', name : 'Pembelian'},
                    {path : `/pembelian/edit/${this.props.pathId}`, name : 'Edit Pembelian', isactive: true},
                  ],
              Title : 'Edit Pembelian'
          },{
              id : 59,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/pembelian', name : 'Pembelian'},
                    {path : `/pembelian/detail/${this.props.pathId}`, name : 'Detail Pembelian', isactive: true},
                  ],
              Title : 'Detail Pembelian'
          },{
              id : 60,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/penjualan', name : 'Penjualan', isactive: true},
                  ],
              Title : 'Penjualan'
          },{
              id : 61,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/penjualan', name : 'Penjualan'},
                    {path : `/penjualan/add`, name : 'Add Penjualan', isactive: true},
                  ],
              Title : 'Add Penjualan'
          },{
              id : 62,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/penjualan', name : 'Penjualan'},
                    {path : `/penjualan/edit/${this.props.pathId}`, name : 'Edit Penjualan', isactive: true},
                  ],
              Title : 'Edit Penjualan'
          },{
              id : 63,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/penjualan', name : 'Penjualan'},
                    {path : `/penjualan/detail/${this.props.pathId}`, name : 'Detail Penjualan', isactive: true},
                  ],
              Title : 'Detail Penjualan'
          },{
              id : 64,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/retur_pembelian', name : 'Retur Pembelian', isactive: true},
                  ],
              Title : 'Retur Pembelian'
          },{
              id : 65,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/retur_pembelian', name : 'Retur Pembelian'},
                    {path : `/retur_pembelian/add`, name : 'Add Retur Pembelian', isactive: true},
                  ],
              Title : 'Add Retur Pembelian'
          },{
              id : 66,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/retur_pembelian', name : 'Retur Pembelian'},
                    {path : `/retur_pembelian/edit/${this.props.pathId}`, name : 'Edit Retur Pembelian', isactive: true},
                  ],
              Title : 'Edit Retur Pembelian'
          },{
              id : 67,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/retur_pembelian', name : 'Retur Pembelian'},
                    {path : `/retur_pembelian/detail/${this.props.pathId}`, name : 'Detail Retur Pembelian', isactive: true},
                  ],
              Title : 'Detail Retur Pembelian'
          },{
              id : 68,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/retur_penjualan', name : 'Retur Penjualan', isactive: true},
                  ],
              Title : 'Retur Penjualan'
          },{
              id : 69,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/retur_penjualan', name : 'Retur Penjualan'},
                    {path : `/retur_penjualan/add`, name : 'Add Retur Penjualan', isactive: true},
                  ],
              Title : 'Add Retur Penjualan'
          },{
              id : 70,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/retur_penjualan', name : 'Retur Penjualan'},
                    {path : `/retur_penjualan/edit/${this.props.pathId}`, name : 'Edit Retur Penjualan', isactive: true},
                  ],
              Title : 'Edit Retur Penjualan'
          },{
              id : 71,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/retur_penjualan', name : 'Retur Penjualan'},
                    {path : `/retur_penjualan/detail/${this.props.pathId}`, name : 'Detail Retur Penjualan', isactive: true},
                  ],
              Title : 'Detail Retur Penjualan'
          },{
              id : 72,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/giro', name : 'Giro/ Cheque', isactive: true},
                  ],
              Title : 'Giro/ Cheque'
          },{
              id : 73,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/giro', name : 'Giro/ Cheque'},
                    {path : `/giro/add`, name : 'Add Giro/ Cheque', isactive: true},
                  ],
              Title : 'Add Giro/ Cheque'
          },{
              id : 74,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/giro', name : 'Giro/ Cheque'},
                    {path : `/giro/edit/${this.props.pathId}`, name : 'Edit Giro/ Cheque', isactive: true},
                  ],
              Title : 'Edit Giro/ Cheque'
          },{
              id : 75,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/giro', name : 'Giro/ Cheque'},
                    {path : `/giro/detail/${this.props.pathId}`, name : 'Detail Giro/ Cheque', isactive: true},
                  ],
              Title : 'Detail Giro/ Cheque'
          },{
              id : 76,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/giro', name : 'Giro/ Cheque'},
                    {path : `/giro/detail/${this.props.pathId}`, name : 'Detail Giro/ Cheque'},
                    {path : `/giro/detail/${this.props.pathId}/kliring`, name : 'Kliring Giro/ Cheque', isactive: true},
                  ],
              Title : 'Kliring Giro/ Cheque'
          },{
              id : 77,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/jurnal_umum', name : 'Jurnal Umum', isactive: true},
                  ],
              Title : 'Jurnal Umum'
          },{
              id : 78,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/jurnal_umum', name : 'Jurnal Umum'},
                    {path : `/jurnal_umum/add`, name : 'Add Jurnal Umum', isactive: true},
                  ],
              Title : 'Add Jurnal Umum'
          },{
              id : 79,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/jurnal_umum', name : 'Jurnal Umum'},
                    {path : `/jurnal_umum/edit/${this.props.pathId}`, name : 'Edit Jurnal Umum', isactive: true},
                  ],
              Title : 'Edit Jurnal Umum'
          },{
              id : 80,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/jurnal_umum', name : 'Jurnal Umum'},
                    {path : `/jurnal_umum/detail/${this.props.pathId}`, name : 'Detail Jurnal Umum', isactive: true},
                  ],
              Title : 'Detail Jurnal Umum'
          },
          {
              id : 81,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/laporan_keuangan', name : 'Laporan Keuangan', isactive: true},
                  ],
              Title : 'Laporan Keuangan'
          },{
              id : 82,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/laporan_keuangan', name : 'Laporan Keuangan'},
                    {path : '/laporan_keuangan/ju', name : 'Jurnal Umum', isactive: true},
                  ],
              Title : 'Jurnal Umum'
          },{
              id : 83,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/laporan_keuangan', name : 'Laporan Keuangan'},
                    {path : '/laporan_keuangan/bb', name : 'Buku Besar', isactive: true},
                  ],
              Title : 'Buku Besar'
          },{
              id : 84,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/laporan_keuangan', name : 'Laporan Keuangan'},
                    {path : '/laporan_keuangan/bb_p', name : 'Buku Besar Piutang', isactive: true},
                  ],
              Title : 'Buku Besar Piutang'
          },{
              id : 85,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/laporan_keuangan', name : 'Laporan Keuangan'},
                    {path : '/laporan_keuangan/bb_h', name : 'Buku Besar Hutang', isactive: true},
                  ],
              Title : 'Buku Besar Hutang'
          },{
              id : 86,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/laporan_keuangan', name : 'Laporan Keuangan'},
                    {path : '/laporan_keuangan/bb_pnj', name : 'Buku Besar Uang Muka Penjualan', isactive: true},
                  ],
              Title : 'Buku Besar Uang Muka Penjualan'
          },{
              id : 87,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/laporan_keuangan', name : 'Laporan Keuangan'},
                    {path : '/laporan_keuangan/bb_pmb', name : 'Buku Besar Uang Muka Pemebelian', isactive: true},
                  ],
              Title : 'Buku Besar Uang Muka Pembelian'
          },{
              id : 88,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/laporan_keuangan', name : 'Laporan Keuangan'},
                    {path : '/laporan_keuangan/ns', name : 'Neraca Saldo', isactive: true},
                  ],
              Title : 'Neraca Saldo'
          },{
              id : 89,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/laporan_keuangan', name : 'Laporan Keuangan'},
                    {path : '/laporan_keuangan/lr', name : 'Laba/ Rugi', isactive: true},
                  ],
              Title : 'Laba/ Rugi'
          },{
              id : 90,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/laporan_keuangan', name : 'Laporan Keuangan'},
                    {path : '/laporan_keuangan/ek', name : 'Ekuitas', isactive: true},
                  ],
              Title : 'Ekuitas'
          },{
              id : 91,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/laporan_keuangan', name : 'Laporan Keuangan'},
                    {path : '/laporan_keuangan/pk', name : 'Posisi Keuangan', isactive: true},
                  ],
              Title : 'Posisi Keuangan'
          },{
              id : 92,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/laporan_keuangan', name : 'Laporan Keuangan'},
                    {path : '/laporan_keuangan/ak', name : 'Arus Kas', isactive: true},
                  ],
              Title : 'Arus Kas'
          },{
              id : 93,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/laporan_keuangan', name : 'Laporan Keuangan'},
                    {path : '/laporan_keuangan/ks', name : 'Kartu Stok', isactive: true},
                  ],
              Title : 'Kartu Stok'
          },{
              id : 94,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/penerimaan', name : 'Penerimaan Pembayaran', isactive: true},
                  ],
              Title : 'Penerimaan Pembayaran'
          },{
              id : 95,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/penerimaan', name : 'Penerimaan Pembayaran'},
                    {path : `/penerimaan/add`, name : 'Add Penerimaan Pembayaran', isactive: true},
                  ],
              Title : 'Add Penerimaan Pembayaran'
          },{
              id : 96,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/penerimaan', name : 'Penerimaan Pembayaran'},
                    {path : `/penerimaan/edit/${this.props.pathId}`, name : 'Edit Penerimaan Pembayaran', isactive: true},
                  ],
              Title : 'Edit Penerimaan Pembayaran'
          },{
              id : 97,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/penerimaan', name : 'Penerimaan Pembayaran'},
                    {path : `/penerimaan/detail/${this.props.pathId}`, name : 'Detail Penerimaan Pembayaran', isactive: true},
                  ],
              Title : 'Detail Penerimaan Pembayaran'
          },{
              id : 98,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/pembayaran', name : 'Pembayaran', isactive: true},
                  ],
              Title : 'Pembayaran'
          },{
              id : 99,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/pembayaran', name : 'Pembayaran'},
                    {path : `/pembayaran/add`, name : 'Add Pembayaran', isactive: true},
                  ],
              Title : 'Add Pembayaran'
          },{
              id : 100,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/pembayaran', name : 'Pembayaran'},
                    {path : `/pembayaran/edit/${this.props.pathId}`, name : 'Edit Pembayaran', isactive: true},
                  ],
              Title : 'Edit Pembayaran'
          },{
              id : 101,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/pembayaran', name : 'Pembayaran'},
                    {path : `/pembayaran/detail/${this.props.pathId}`, name : 'Detail Pembayaran', isactive: true},
                  ],
              Title : 'Detail Pembayaran'
          },{
              id : 102,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/stock_opname', name : 'Stock Opname', isactive: true},
                  ],
              Title : 'Stock Opname'
          },{
              id : 103,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/stock_opname', name : 'Stock Opname'},
                    {path : `/stock_opname/add`, name : 'Add Stock Opname', isactive: true},
                  ],
              Title : 'Add Stock Opname'
          },{
              id : 104,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/kat_items', name : 'Kategori Item', isactive: true},
                  ],
              Title : 'Kategori Item'
          },{
              id : 105,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/kat_items', name : 'Kategori Item'},
                    {path : `/kat_items/add`, name : 'Add Kategori Item', isactive: true},
                  ],
              Title : 'Add Kategori Item'
          },{
              id : 106,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/kat_items', name : 'Kategori Item'},
                    {path : `/kat_items/edit/${this.props.pathId}`, name : 'Edit Kategori Item', isactive: true},
                  ],
              Title : 'Edit Kategori Item'
          },{
              id : 107,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/kat_items', name : 'Kategori Item'},
                    {path : `/kat_items/detail/${this.props.pathId}`, name : 'Detail Kategori Item', isactive: true},
                  ],
              Title : 'Detail Kategori Item'
          },{
              id : 108,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/paket_items', name : 'Paket Item', isactive: true},
                  ],
              Title : 'Paket Item'
          },{
              id : 109,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/paket_items', name : 'Paket Item'},
                    {path : `/paket_items/add`, name : 'Add Paket Item', isactive: true},
                  ],
              Title : 'Add Paket Item'
          },{
              id : 110,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/paket_items', name : 'Paket Item'},
                    {path : `/paket_items/edit/${this.props.pathId}`, name : 'Edit Paket Item', isactive: true},
                  ],
              Title : 'Edit Paket Item'
          },{
              id : 111,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/regional', name : 'Regional', isactive: true},
                  ],
              Title : 'Regional'
          },{
              id : 112,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/regional', name : 'Regional'},
                    {path : `/regional/add`, name : 'Add Regional', isactive: true},
                  ],
              Title : 'Add Regional'
          },{
              id : 113,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/regional', name : 'Regional'},
                    {path : `/regional/edit/${this.props.pathId}`, name : 'Edit Regional', isactive: true},
                  ],
              Title : 'Edit Regional'
          },
          {
              id : 114,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/bom', name : 'Bill of Materials', isactive: true},
                  ],
              Title : 'Bill of Materials'
          },{
              id : 115,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/bom', name : 'Bill of Materials'},
                    {path : `/bom/add`, name : 'Add Bill of Materials', isactive: true},
                  ],
              Title : 'Add Bill of Materials'
          },{
              id : 116,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/bom', name : 'Bill of Materials'},
                    {path : `/bom/edit/${this.props.pathId}`, name : 'Edit Bill of Materials', isactive: true},
                  ],
              Title : 'Edit Bill of Materials'
          },{
              id : 117,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/bom', name : 'Bill of Materials'},
                    {path : `/bom/detail/${this.props.pathId}`, name : 'Detail Bill of Materials', isactive: true},
                  ],
              Title : 'Detail Bill of Materials'
          },{
              id : 118,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/produksi', name : 'Produksi', isactive: true},
                  ],
              Title : 'Produksi'
          },{
              id : 119,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/produksi', name : 'Produksi'},
                    {path : `/produksi/add`, name : 'Add Produksi', isactive: true},
                  ],
              Title : 'Add/ Start Produksi'
          },{
              id : 120,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/produksi', name : 'Produksi'},
                    {path : `/produksi/edit/${this.props.pathId}`, name : 'Edit Produksi', isactive: true},
                  ],
              Title : 'Edit Produksi'
          },{
              id : 121,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/produksi', name : 'Produksi'},
                    {path : `/produksi/detail/${this.props.pathId}`, name : 'Detail Produksi', isactive: true},
                  ],
              Title : 'Detail Produksi'
          },{
              id : 122,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/cara_pembayaran', name : 'Cara Pembayaran', isactive: true},
                  ],
              Title : 'Cara Pembayaran'
          },{
              id : 123,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/cara_pembayaran', name : 'Cara Pembayaran'},
                    {path : `/cara_pembayaran/add`, name : 'Add Cara Pembayaran', isactive: true},
                  ],
              Title : 'Add Cara Pembayaran'
          },{
              id : 124,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/cara_pembayaran', name : 'Cara Pembayaran'},
                    {path : `/cara_pembayaran/edit/${this.props.pathId}`, name : 'Edit Cara Pembayaran', isactive: true},
                  ],
              Title : 'Edit Cara Pembayaran'
          },{
              id : 125,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/ketentuan_diskon', name : 'Ketentuan Diskon', isactive: true},
                  ],
              Title : 'Ketentuan Diskon'
          },{
              id : 126,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/ketentuan_diskon', name : 'Ketentuan Diskon'},
                    {path : `/ketentuan_diskon/add`, name : 'Add Ketentuan Diskon', isactive: true},
                  ],
              Title : 'Add Ketentuan Diskon'
          },{
              id : 127,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/ketentuan_diskon', name : 'Ketentuan Diskon'},
                    {path : `/ketentuan_diskon/edit/${this.props.pathId}`, name : 'Edit Ketentuan Diskon', isactive: true},
                  ],
              Title : 'Edit Ketentuan Diskon'
          },
          {
              id : 128,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/setting_lainnya', name : 'Setting Lainnya', isactive: true},
                  ],
              Title : 'Setting Lainnya'
          },{
              id : 129,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/items', name : 'Items'},
                    {path : `/items/detail/${this.props.pathId}`, name : 'Detail Items', isactive: true},
                  ],
              Title : 'Detail Items'
          },{
              id : 130,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/items', name : 'Items'},
                    {path : `/items/detail/${this.props.pathId}`, name : 'Detail Items'},
                    {path : `/items/detail/${this.props.pathId}/detail_info`, name : 'Detail Info', isactive: true}
                  ],
              Title : 'Detail Info Items'
          },{
              id : 131,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/items', name : 'Items'},
                    {path : `/items/detail/${this.props.pathId}`, name : 'Detail Items'},
                    {path : `/items/detail/${this.props.pathId}/harga_jual`, name : 'Harga Jual', isactive: true}
                  ],
              Title : 'Harga Jual'
          },{
              id : 132,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/paket_items', name : 'Paket Item'},
                    {path : `/paket_items/detail/${this.props.pathId}`, name : 'Detail Paket Item', isactive: true},
                  ],
              Title : 'Detail Items'
          },{
              id : 133,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/paket_items', name : 'Paket Item'},
                    {path : `/paket_items/detail/${this.props.pathId}`, name : 'Detail Paket Item'},
                    {path : `/paket_items/detail/${this.props.pathId}/detail_info`, name : 'Detail Info', isactive: true}
                  ],
              Title : 'Detail Info Paket Item'
          },{
              id : 134,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/paket_items', name : 'Paket Item'},
                    {path : `/paket_items/detail/${this.props.pathId}`, name : 'Detail Paket Item'},
                    {path : `/paket_items/detail/${this.props.pathId}/harga_jual`, name : 'Harga Jual', isactive: true}
                  ],
              Title : 'Harga Jual Paket Item'
          },{
              id : 135,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/mutasi_stock', name : 'Transfer Stock', isactive: true},
                  ],
              Title : 'Transfer Stock'
          },{
              id : 136,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/mutasi_stock', name : 'Transfer Stock'},
                    {path : `/mutasi_stock/add`, name : 'Add Transfer Stock', isactive: true},
                  ],
              Title : 'Add Transfer Stock'
          },{
              id : 137,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/mutasi_stock', name : 'Transfer Stock'},
                    {path : `/mutasi_stock/edit/${this.props.pathId}`, name : 'Edit Transfer Stock', isactive: true},
                  ],
              Title : 'Edit Transfer Stock'
          },{
              id : 138,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/mutasi_stock', name : 'Transfer Stock'},
                    {path : `/mutasi_stock/detail/${this.props.pathId}`, name : 'Detail Transfer Stock', isactive: true},
                  ],
              Title : 'Detail Transfer Stock'
          },
          {
              id : 139,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/laporan_keuangan', name : 'Laporan Keuangan'},
                    {path : '/laporan_keuangan/rp', name : 'Rekap Penjualan', isactive: true},
                  ],
              Title : 'Rekap Penjualan'
          }, 
          {
              id : 140,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/laporan_keuangan', name : 'Laporan Keuangan'},
                    {path : '/laporan_keuangan/pip', name : 'Penjualan Per Item & Pelanggan', isactive: true},
                  ],
              Title : 'Penjualan Per Item & Pelanggan'
          },{
              id : 141,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/laporan_keuangan', name : 'Laporan Keuangan'},
                    {path : '/laporan_keuangan/pkt', name : 'Komposisi Paket', isactive: true},
                  ],
              Title : 'Komposisi Paket'
          },{
              id : 142,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/stock_opname', name : 'Stock Opname'},
                    {path : `/stock_opname/detail/${this.props.pathId}`, name : 'Detail Stock Opname', isactive: true},
                  ],
              Title : 'Detail Stock Opname'
          },{
              id : 143,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/laporan_keuangan', name : 'Laporan Keuangan'},
                    {path : '/laporan_keuangan/lo', name : 'Laporan Operasional', isactive: true},
                  ],
              Title : 'Laporan Operasional'
          },{
              id : 144,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/laporan_keuangan', name : 'Laporan Keuangan'},
                    {path : '/laporan_keuangan/lr_p', name : 'Laba/ Rugi Perbandingan', isactive: true},
                  ],
              Title : 'Laba/ Rugi Perbandingan'
          },{
              id : 145,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/items', name : 'Items'},
                    {path : `/items/detail/${this.props.pathId}`, name : 'Detail Items'},
                    {path : `/items/detail/${this.props.pathId}/pajak`, name : 'Pajak', isactive: true}
                  ],
              Title : 'Pajak'
          },{
              id : 146,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/paket_items', name : 'Paket Items'},
                    {path : `/paket_items/detail/${this.props.pathId}`, name : 'Detail Paket Items'},
                    {path : `/paket_items/detail/${this.props.pathId}/pajak`, name : 'Pajak', isactive: true}
                  ],
              Title : 'Pajak'
          },
          {
              id : 147,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/stock_opname', name : 'Stock Opname'},
                    {path : `/stock_opname/edit/${this.props.pathId}`, name : 'Edit Stock Opname', isactive: true},
                  ],
              Title : 'Edit Stock Opname'
          },{
              id : 148,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/discount', name : 'Ketentuan Diskon', isactive: true},
                  ],
              Title : 'Ketentuan Diskon'
          },{
              id : 149,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/discount', name : 'Diskon'},
                    {path : `/discount/add`, name : 'Add Ketentuan Diskon', isactive: true},
                  ],
              Title : 'Add Ketentuan Diskon'
          },{
              id : 150,
              link : [
                    {path : '/', name : 'Dashboard'},
                    {path : '/discount', name : 'Diskon'},
                    {path : `/discount/edit/${this.props.pathId}`, name : 'Edit Ketentuan Diskon', isactive: true},
                  ],
              Title : 'Edit Ketentuan Diskon'
          }
        ]
    }
  }

  render (){
    const { Title, link } = this.state.br.find( ({ id }) => id === this.props.id );
    return(
         <div className="row wrapper border-bottom white-bg page-heading">
             <div className="col-lg-10">
                     <h2>{Title}</h2>
                     <ol className="breadcrumb">
                       {link.map((d ) =>
                      <li className="breadcrumb-item" style={{fontSize : 11}}>
                          {(d.isactive) ? <strong><Link to={d.path}>{d.name}</Link></strong> : <Link to={d.path}>{d.name}</Link>}
                      </li>
                      )}
                    </ol>
                </div>     
          </div>
        
      )
  }
}

export { Breadcrumb }

