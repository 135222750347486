import React, { Component } from 'react';
import { authenticationService } from '../_services';

class HakAkses extends React.Component {
   constructor(props) {
        super(props);
        this.state = {
            ability : authenticationService.ability
        }
    }

   render() {
   	// console.log(this.state.ability)
   	if(this.state.ability){
	   	const bisaakses= this.state.ability.find(e=> e === this.props.id)
	    return (
	        <React.Fragment>
	        {bisaakses ? this.props.children : null}
	      	</React.Fragment>
	    );
	}else{
		return null	
	}
  }
}

export { HakAkses };
